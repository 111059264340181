import React from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

const PendingFinishedToggle = ({ value, onSwitchChanged }) => {
  const safeValue = value ?? {};

  const handleChange = (event, newSelection) => {
    if (newSelection !== null) {
      const newState = {
        onlyFinished: newSelection === "onlyFinished",
        onlyPending: newSelection === "onlyPending",
        onlyExpired: newSelection === "onlyExpired",
      };
      onSwitchChanged(newState);
    }
  };

  const getSelectedValue = () => {
    if (safeValue.onlyFinished) return "onlyFinished";
    if (safeValue.onlyPending) return "onlyPending";
    if (safeValue.onlyExpired) return "onlyExpired";
    return "all";
  };

  const buttonStyles = (selected) => ({
    px: 2,
    py: 0.5,
    fontSize: "0.8rem",
    bgcolor: selected ? selected : "transparent",
    "&.Mui-selected, &.Mui-selected:hover": {
      bgcolor: selected,
      color: "#000",
    },
  });

  return (
    <ToggleButtonGroup
      value={getSelectedValue()}
      exclusive
      onChange={handleChange}
      size="small"
      sx={{ borderRadius: "8px", boxShadow: 1, mt: 1 }}
    >
      <ToggleButton value="all" sx={buttonStyles(value.all && "#E3F2FD")}>
        All
      </ToggleButton>
      <ToggleButton
        value="onlyFinished"
        sx={buttonStyles(value.onlyFinished && "#C8E6C9")}
      >
        Only Finished
      </ToggleButton>
      <ToggleButton
        value="onlyPending"
        sx={buttonStyles(value.onlyPending && "#FFF9C4")}
      >
        Only Pending
      </ToggleButton>
      <ToggleButton
        value="onlyExpired"
        sx={buttonStyles(value.onlyExpired && "#FFCDD2")}
      >
        Only Expired
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default PendingFinishedToggle;
