import { ToggleButton, ToggleButtonGroup, Box } from "@mui/material";

function ReviewedStatusToggle({ active, handleClickToggleButtonGroup }) {
  const handleChangeButtonGroup = (event, newSelection) => {
    if (newSelection !== null) {
      handleClickToggleButtonGroup(newSelection);
    }
  };

  const buttonStyles = (selected) => ({
    px: 2,
    py: 0.5,
    fontSize: "0.8rem",
    bgcolor: selected ? selected : "transparent",
    "&.Mui-selected, &.Mui-selected:hover": {
      bgcolor: selected,
      color: "#000",
    },
  });
  return (
    <Box display="flex" flexDirection="column" alignItems="left" gap={1} mt={1}>
      <ToggleButtonGroup
        value={active}
        exclusive
        onChange={handleChangeButtonGroup}
        size="small"
        sx={{
          borderRadius: "8px",
          boxShadow: 1,
          width: "fit-content",
          marginLeft: 0,
        }}
      >
        <ToggleButton
          value="Both"
          sx={buttonStyles(active === "Both" && "#E3F2FD")}
        >
          Both
        </ToggleButton>

        <ToggleButton
          value="reviewed"
          sx={buttonStyles(active === "reviewed" && "#C8E6C9")}
        >
          Reviewed
        </ToggleButton>

        <ToggleButton
          value="unreviewed"
          sx={buttonStyles(active === "unreviewed" && "#FFCDD2")}
        >
          Not Reviewed
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
}

export default ReviewedStatusToggle;
