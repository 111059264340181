import { parseISO } from "date-fns";

import { backend_client } from "../../../helpers/api";

const convertLocalDateToUTCStringRemoveZ = (date) => {
  return date ? date.toISOString().replace("Z", "") : null;
};

const convertUTCStringToLocalDate = (utcDateString) => {
  return utcDateString ? parseISO(utcDateString + "Z") : null;
};

const endpoint = "/exam-instances/search";

const findExamInstances = async (formData) => {
  const {
    examineeEmails,
    examDesignIds,
    tagIds,
    onlyFinished,
    onlyPending,
    onlyExpired,
    startedBefore,
    startedAfter,
    finishedBefore,
    finishedAfter,
    createdBefore,
    createdAfter,
    availableBefore,
    availableAfter,
    minScore,
    maxScore,
    createdBy,
    is_reviewed,
  } = formData;

  const query = {
    examinee_emails: examineeEmails,
    exam_design_ids: examDesignIds,
    tag_ids: tagIds,
    only_finished: onlyFinished,
    only_pending: onlyPending,
    only_expired: onlyExpired,
    started_before: convertLocalDateToUTCStringRemoveZ(startedBefore),
    started_after: convertLocalDateToUTCStringRemoveZ(startedAfter),
    finished_before: convertLocalDateToUTCStringRemoveZ(finishedBefore),
    finished_after: convertLocalDateToUTCStringRemoveZ(finishedAfter),
    created_before: convertLocalDateToUTCStringRemoveZ(createdBefore),
    created_after: convertLocalDateToUTCStringRemoveZ(createdAfter),
    available_before: convertLocalDateToUTCStringRemoveZ(availableBefore),
    available_after: convertLocalDateToUTCStringRemoveZ(availableAfter),
    min_score: minScore !== "" ? minScore : null,
    max_score: maxScore !== "" ? maxScore : null,
    created_by: createdBy,
    is_reviewed: is_reviewed,
  };

  if (is_reviewed == "reviewed") {
    query.is_reviewed = true;
  } else if (is_reviewed == "unreviewed") {
    query.is_reviewed = false;
  } else {
    query.is_reviewed = null;
  }

  return backend_client
    .post(endpoint, query)
    .then((res) => res.data)
    .then((responseExamInstances) =>
      mapResponseToExamInstances(responseExamInstances)
    );
};
const mapResponseToExamInstances = (response) => {
  return response.map((i) => ({
    id: i.id,
    examineeEmail: i.user_email,
    examDesignId: i.exam_design_id,
    score: i.score,
    tags: i.tags,
    startTime: convertUTCStringToLocalDate(i.start_time),
    finishTime: convertUTCStringToLocalDate(i.finish_time),
    availableFrom: convertUTCStringToLocalDate(i.available_from),
    availableUntil: convertUTCStringToLocalDate(i.available_until),
    createdOn: convertUTCStringToLocalDate(i.created_on),
    createdBy: i.created_by,
    is_reviewed: i.is_reviewed,
    user: i.user,
  }));
};

export default findExamInstances;
